import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Layout from "../../components/Layout";
import RecentNews from "../../components/RecentNews/RecentNews";
import BuildingGuideCard from "../../components/BuildingGuideCard/BuildingGuideCard";
import BuildingGuideModal from "../../components/Modals/BuildingGuideModal/BuildingGuideModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal'
import { setModal } from '../../reducers/Home/actions'
import { getGuides } from "../../reducers/BuildingGuide/actions";
import { connect } from "react-redux";
import { graphql } from "gatsby";
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const BuildingGuidePage = ({ data, guides, page, successModal, getGuides, setModal }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    getGuides({ page });
  }, [getGuides, page]);

  const staticGuides = data.allStrapiBuildingguides.edges;

  const staticLastProject = data && data.lastProjects.edges;
  const staticLastNew = data && data.lastNews.edges;
  const staticLastArticle = data && data.lastArticles.edges;

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="building-guide-wrapper">
        <div className="building-guide-container">
          <div className="building-guide-title">
            <h1 className='h2 font-weight-bold'>Строителен наръчник</h1>
          </div>
          <div className="building-guide-card-container">
            {guides && staticGuides &&
              staticGuides.map((g, i) => {
                return (
                  <div key={g.node.id}>
                    <Row className="justify-content-center">
                      <Col sm={10} className="building-guide-card-col">
                        <BuildingGuideCard
                          id={g.node.id}
                          btnText="свали брошурата"
                          btnClass="building-guide-card-btn"
                          cardClass="building-guide-card"
                          imageClass="building-guide-card-img"
                          cardBodyClass="building-guide-card-custom-body"
                          title={g.node.title}
                          desc={g.node.description}
                          isGatsbyImage={true}
                          imageObjectFit='contain'
                          image={g.node.image.localFile && g.node.image.localFile.childImageSharp}
                          url="/building-guide"
                          onClick={() => {
                            setId(g.node.id);
                            setModalOpen(!isModalOpen);
                          }}
                        />
                      </Col>
                    </Row>
                    {i !== guides.length - 1 && <hr />}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <RecentNews
        staticLastProject={staticLastProject}
        staticLastNew={staticLastNew}
        staticLastArticle={staticLastArticle}
      />
      <BuildingGuideModal
        guide
        title="Попълнете формата и свалете брошурата"
        isOpen={isModalOpen}
        id={id}
        onClick={() => {
          setModalOpen(!isModalOpen);
        }}
      />
      <ConfirmationModal
        title={`Брошурата е изпратена на посочения имейл.`}
        openFromGuides={true}
        isOpen={successModal}
        onClick={() => setModal()}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    guides: state.guides.data,
    page: state.guides.current_page,
    successModal: state.home.successModal
  };
};

const mapDispatchToProps = {
  getGuides,
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingGuidePage);

export const query = graphql`
  {
    allStrapiBuildingguides {
      edges {
        node {
          id: strapiId
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    },
    lastArticles: allStrapiArticleslast {
      edges {
        node {
          id: strapiId
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    },
    lastNews: allStrapiNewslast {
      edges {
        node {
      id: strapiId
      title
      description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
    }
   }
  },
    lastProjects: allStrapiProjectslast {
      edges {
        node {
          id: strapiId
          title
          author_name
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    },
  }
`
