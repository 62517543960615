import React from "react";
import {
  Card,
  CardBody,
  Button,
} from "reactstrap";
import { Link, navigate } from "gatsby";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import "./styles.scss";

const BuildingGuideCard = ({
  url,
  btnText,
  btnClass,
  cardClass,
  cardBodyClass,
  imageClass,
  serviceInfo,
  image,
  title,
  subtitle,
  desc,
  onClick,
  isGatsbyImage,
  imageObjectFit
}) => {
    return (
      <Card className={cardClass ? `${cardClass} shadow bg-white align-items-center` : "shadow bg-white align-items-center"}>
        <div className='building-card-img-container d-flex'>
          {
            isGatsbyImage
              ? <GatsbyImage
                image={getImage(image)}
                alt=""
                objectFit={imageObjectFit ? imageObjectFit : 'cover'}
                layout="fixed"
                className='mx-auto'
              />
              : image
                ? <div
                  id={imageClass ? `${imageClass}` : ''}
                  className={'building-card-image'}
                  style={{ backgroundImage: `url(${image})` }}
                />
                : <div
                  id={imageClass ? `${imageClass}` : ''}
                  className={'building-card-image-default'}
                />
          }
        </div>
        <CardBody className={cardBodyClass ? `${cardBodyClass} card-text-container` : "card-text-container"}>
          {
            serviceInfo
              ? <div className="buidlign-services-info-container">{serviceInfo}</div>
              : null
          }
          <div className='building-card-title-container'>
            <div className='card-title'>
              <h4 className="mb-3 font-weight-bold">{title}</h4>
              {
                subtitle
                  ? <h5 className="mb-3 color-gray">на {subtitle}</h5>
                  : null
              }

            </div>
            {desc !== undefined && <div
              className='building-card-description'
              dangerouslySetInnerHTML={{ __html: desc }}
            />}
          </div>

          <div
            role='none'
            className="nav-link no-underline-link my-3"
            onClick={() => url && navigate(url)}
          >
            <Button
              outline
              onClick={onClick}
              className={`${btnClass
                ? `${btnClass} btn btn-lg shadow card-btn-style text-uppercase `
                : "btn btn-lg shadow card-btn-style text-uppercase"}`}
            >
              {`${btnText ? btnText : "виж повече"}`}
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  };

export default BuildingGuideCard;
